import "./Home.scss";
import Page from "../../components/StructureElement/Page/Page";
import Container from "../../components/BlockElement/Container/Container";
import Hr from "../../components/TextElement/Hr/Hr";
import Headline from "../../components/Headline/Headline";
import Tests from "../../components/Tests/Tests";
import { headline } from "../../utils/headline";

export default function Home(){
    return(
        <Page>
            <Container className="home">
                <Hr className="home__hr" items={[]} htmlFor={""} />
                <Headline {...headline} />
                <Tests />
            </Container>
        </Page>
    );

}