import { useState, useEffect } from 'react';

export const useDisplay = (breakpoint = 768) => {
    const [isMobile, setIsMobile] = useState(() => {
        if (typeof window !== 'undefined') { return window.innerWidth <= breakpoint; }
        return false;
    });

    useEffect(() => {
        if (typeof window === 'undefined') return; // Avoid adding event listener if window is undefined
        const handleResize = () => { setIsMobile(window.innerWidth <= breakpoint); };
        window.addEventListener('resize', handleResize); // Add event listener
        handleResize();    
        return () => { window.removeEventListener('resize', handleResize); }; // Cleanup event listener on unmount
    }, [breakpoint]);

    return { isMobile };
};
