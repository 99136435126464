import { IQuestion } from "../types/IQuestion";

export const iwjo: IQuestion[] = [
  {
    title: "Urządzenie techniczne objęte dozorem technicznym może być eksploatowane na podstawie:",
    a: "zezwolenia ustnego lub pisemnego wydanego przez upoważnionego konserwatora",
    b: "ważnej decyzji zezwalającej na eksploatację wydanej przez organ właściwej jednostki dozoru technicznego",
    c: "oznaczenia CE zamieszczonego na urządzeniu",
    d: "deklaracji zgodności wystawionej przez wytwórcę",
    answer: "b"
  },
  {
    title: "Które z wymienionych czynności nie należą do zakresu obowiązków obsługującego UTB:",
    a: "wykonywanie niewielkich napraw urządzenia w ramach posiadanego wykształcenia i umiejętności",
    b: "przestrzeganie instrukcji eksploatacji w zakresie obsługi UTB",
    c: "zapoznanie się z planem pracy i wielkością przenoszonych ładunków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzeń technicznych:",
    a: "są zawarte w instrukcji eksploatacji urządzenia",
    b: "określa konserwator urządzenia",
    c: "określa w protokole inspektor wykonujący badanie",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Dozorem technicznym nazywamy:",
    a: "określone ustawą działania zmierzające do zapewnienia bezpiecznego funkcjonowania urządzeń technicznych i urządzeń do odzyskiwania par paliwa oraz działania zmierzające do zapewnienia bezpieczeństwa publicznego w tych obszarach",
    b: "instytucje kontrolujące stan techniczny urządzeń",
    c: "Urząd Dozoru Technicznego",
    d: "UDT, WDT, TDT",
    answer: "a"
  },
  {
    title: "Dozór techniczny nad urządzeniami technicznymi wykonuje:",
    a: "Urząd Dozoru Technicznego oraz specjalistyczne jednostki dozoru technicznego",
    b: "Urząd Dozoru Technicznego oraz upoważnione przez UDT organizacje",
    c: "Urząd Dozoru Technicznego i zagraniczne jednostki dozoru technicznego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Zmiana parametrów technicznych lub zmiana konstrukcji urządzenia technicznego traktowana jest jako:",
    a: "modernizacja urządzenia technicznego",
    b: "naprawa urządzenia technicznego",
    c: "usuwanie usterek i innych nieprawidłowości urządzenia technicznego",
    d: "wytworzenie nowego urządzenia",
    answer: "a"
  },
  {
    title: "Uzgodnioną naprawę lub modernizację urządzeń technicznych może wykonać:",
    a: "naprawiający lub modernizujący, który posiada uprawnienie wydane przez organ właściwej jednostki dozoru technicznego",
    b: "eksploatujący urządzenie techniczne posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    c: "konserwator posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    d: "w niewielkim zakresie kompetentny operator",
    answer: "a"
  },
  {
    title: "Ustawa o dozorze technicznym określa następujące formy dozoru technicznego:",
    a: "całkowita, częściowa, ograniczona",
    b: "pełna, ograniczona, uproszczona",
    c: "pełna, cykliczna, sporadyczna",
    d: "UDT, WDT, TDT",
    answer: "b"
  },
  {
    title: "Decyzję zezwalającą na eksploatację urządzenia technicznego wydaje:",
    a: "konserwator po wykonaniu przeglądu z wynikiem pozytywnym",
    b: "organ właściwej jednostki dozoru technicznego lub eksploatujący urządzenie techniczne z upoważnienia organu właściwej jednostki dozoru technicznego",
    c: "organ właściwej jednostki dozoru technicznego lub organ administracji publicznej z upoważnienia organu właściwej jednostki dozoru technicznego",
    d: "organ właściwej jednostki dozoru technicznego",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada ważną decyzję zezwalającą na eksploatację jeżeli wymagają tego odpowiednie przepisy",
    b: "posiada zaświadczenie kwalifikacyjne odpowiedniej kategorii",
    c: "urządzenie posiada aktualny pozytywny wynik przeglądu konserwacyjnego",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada aktualny wpis w dzienniku konserwacji potwierdzający sprawność urządzenia",
    b: "przeszedł odpowiednie szkolenie stanowiskowe",
    c: "urządzenie posiada ważną decyzję zezwalającą na eksploatację",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "W przypadku nieprzestrzegania przez eksploatującego przepisów o dozorze technicznym eksploatujący:",
    a: "otrzymuje pisemne upomnienie",
    b: "otrzymuje zalecenia pokontrolne",
    c: "podlega grzywnie lub karze ograniczenia wolności",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "W przypadku stwierdzenia zagrożenia dla życia lub zdrowia ludzkiego oraz mienia i środowiska inspektor:",
    a: "wydaje decyzję wstrzymującą eksploatację urządzenia technicznego",
    b: "wystawia mandat karny",
    c: "pisemnie poucza eksploatującego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "W przypadku niebezpiecznego uszkodzenia urządzenia technicznego lub nieszczęśliwego wypadku eksploatujący:",
    a: "niezwłocznie powiadamia UDT o zaistniałym zdarzeniu",
    b: "powiadamia producenta urządzenia o przyczynach powstałego zdarzenia",
    c: "niezwłocznie dokonuje naprawy urządzenia i przekazuje do dalszej eksploatacji",
    d: "zgłasza urządzenie do wykonania naprawy",
    answer: "a"
  },
  {
    title: "Zaświadczenie kwalifikacyjne do obsługi może zostać cofnięte przez:",
    a: "organ właściwej jednostki dozoru technicznego",
    b: "eksploatującego urządzenie techniczne",
    c: "inspektora BHP",
    d: "Państwową Inspekcję Pracy",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne nieobjęte dozorem technicznym to:",
    a: "żurawie o udźwigu do 3,2 t",
    b: "wciągniki i wciągarki oraz suwnice",
    c: "wózki jezdniowe podnośnikowe oraz podesty ruchome",
    d: "zawiesia transportowe",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne objęte dozorem technicznym to:",
    a: "przenośniki kabinowe i krzesełkowe",
    b: "układnice magazynowe oraz urządzenia dla osób niepełnosprawnych",
    c: "wyciągi towarowe i wyciągi statków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Niebezpieczne uszkodzenie urządzenia technicznego to:",
    a: "każda usterka UTB",
    b: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nadaje się do częściowej eksploatacji",
    c: "nieprzewidziane uszkodzenie, w wyniku którego nadaje się do eksploatacji tylko przy obniżonych parametrach",
    d: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nie nadaje się do eksploatacji lub jego dalsza eksploatacja stanowi zagrożenie",
    answer: "d"
  },
  {
    title: "Nieszczęśliwy wypadek to:",
    a: "nagłe zdarzenie, które spowodowało obrażenia ciała lub śmierć",
    b: "nagłe zdarzenie, które spowodowało przerwę w pracy",
    c: "nagłe zdarzenie, które skutkuje wyłączeniem urządzenia technicznego z eksploatacji",
    d: "każda usterka UTB spowodowana przyczyną losową",
    answer: "a"
  },
  {
    title: "Podnoszenie i przenoszenie osób przez urządzenie techniczne przeznaczone wyłącznie do transportu ładunków wymaga:",
    a: "uzgodnienia z organem właściwej jednostki dozoru technicznego",
    b: "uzgodnienia z przełożonym",
    c: "uzgodnienia ze służbą BHP",
    d: "jest możliwe przy zachowaniu szczególnej ostrożności i pod nadzorem inspektora",
    answer: "a"
  },
  {
    title: "Odpowiedzialnym za zapewnienie właściwej obsługi i konserwacji urządzenia technicznego jest:",
    a: "eksploatujący urządzenie techniczne",
    b: "organ właściwej jednostki dozoru technicznego",
    c: "Państwowa Inspekcja Pracy",
    d: "inspektor UDT",
    answer: "a"
  },
  {
    title: "Wymagane przepisami prawa przeglądy konserwacyjne wykonuje:",
    a: "osoba posiadająca zaświadczenie kwalifikacyjne do konserwacji",
    b: "pracownik autoryzowanego serwisu producenta urządzenia (pod warunkiem posiadania zaświadczeń kwalifikacyjnych do konserwacji)",
    c: "zakładowe służby utrzymania ruchu",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "Dziennik konserwacji urządzenia technicznego prowadzi:",
    a: "inspektor UDT w księdze rewizyjnej urządzenia",
    b: "uprawniony operator",
    c: "wyznaczony pracownik eksploatującego",
    d: "konserwator urządzenia technicznego",
    answer: "d"
  },
  {
    title: "Badania odbiorcze przeprowadza się dla urządzeń technicznych:",
    a: "w terminach zgodnych z zapisami rozporządzenia w sprawie warunków technicznych dozoru technicznego",
    b: "przed wydaniem pierwszej decyzji zezwalającej na eksploatacje",
    c: "po naprawie urządzenia technicznego",
    d: "po każdej zmianie eksploatującego",
    answer: "b"
  },
  {
    title: "Badania okresowe przeprowadza się dla urządzeń technicznych objętych dozorem:",
    a: "ograniczonym",
    b: "pełnym",
    c: "uproszczonym",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Nieobecność konserwującego na badaniu urządzenia technicznego wymaga min.:",
    a: "wcześniejszego uzgodnienia tego faktu z organem właściwej jednostki dozoru technicznego",
    b: "przedstawienia pisemnego usprawiedliwienia nieobecności konserwatora",
    c: "przedstawienia zwolnienia lekarskiego potwierdzającego niezdolność konserwatora do pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "a"
  },
  {
    title: "Kandydat na obsługującego urządzenie techniczne musi:",
    a: "mieć ukończone 18 lat",
    b: "posiadać przynajmniej wykształcenie zawodowe",
    c: "posiadać przynajmniej 1 rok stażu pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne ma obowiązek przerwać pracę gdy:",
    a: "jego stan fizyczny i psychiczny w dniu pracy jest nieodpowiedni",
    b: "stwierdzi, że dalsza praca urządzeniem stwarza zagrożenie",
    c: "urządzenie jest niesprawne",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzenia mogą być określone:",
    a: "w instrukcji eksploatacji urządzenia",
    b: "w ustawie o dozorze technicznym",
    c: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    d: "odpowiedź a i c jest prawidłowa",
    answer: "d"
  },
  {
    title: "Terminy badań okresowych i doraźnych kontrolnych UTB określone są:",
    a: "w ustawie o dozorze technicznym",
    b: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    c: "w dokumentacji konstrukcyjnej urządzenia",
    d: "w dzienniku konserwacji",
    answer: "b"
  }
];

export const iiwjo: IQuestion[] = [
  {
    title: "Urządzenie techniczne objęte dozorem technicznym może być eksploatowane na podstawie:",
    a: "zezwolenia ustnego lub pisemnego wydanego przez upoważnionego konserwatora",
    b: "ważnej decyzji zezwalającej na eksploatację wydanej przez organ właściwej jednostki dozoru technicznego",
    c: "oznaczenia CE zamieszczonego na urządzeniu",
    d: "deklaracji zgodności wystawionej przez wytwórcę",
    answer: "b"
  },
  {
    title: "Które z wymienionych czynności nie należą do zakresu obowiązków obsługującego UTB:",
    a: "wykonywanie niewielkich napraw urządzenia w ramach posiadanego wykształcenia i umiejętności",
    b: "przestrzeganie instrukcji eksploatacji w zakresie obsługi UTB",
    c: "zapoznanie się z planem pracy i wielkością przenoszonych ładunków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzeń technicznych:",
    a: "są zawarte w instrukcji eksploatacji urządzenia",
    b: "określa konserwator urządzenia",
    c: "określa w protokole inspektor wykonujący badanie",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Dozorem technicznym nazywamy:",
    a: "określone ustawą działania zmierzające do zapewnienia bezpiecznego funkcjonowania urządzeń technicznych i urządzeń do odzyskiwania par paliwa oraz działania zmierzające do zapewnienia bezpieczeństwa publicznego w tych obszarach",
    b: "instytucje kontrolujące stan techniczny urządzeń",
    c: "Urząd Dozoru Technicznego",
    d: "UDT, WDT, TDT",
    answer: "a"
  },
  {
    title: "Dozór techniczny nad urządzeniami technicznymi wykonuje:",
    a: "Urząd Dozoru Technicznego oraz specjalistyczne jednostki dozoru technicznego",
    b: "Urząd Dozoru Technicznego oraz upoważnione przez UDT organizacje",
    c: "Urząd Dozoru Technicznego i zagraniczne jednostki dozoru technicznego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Zmiana parametrów technicznych lub zmiana konstrukcji urządzenia technicznego traktowana jest jako:",
    a: "modernizacja urządzenia technicznego",
    b: "naprawa urządzenia technicznego",
    c: "usuwanie usterek i innych nieprawidłowości urządzenia technicznego",
    d: "wytworzenie nowego urządzenia",
    answer: "a"
  },
  {
    title: "Uzgodnioną naprawę lub modernizację urządzeń technicznych może wykonać:",
    a: "naprawiający lub modernizujący, który posiada uprawnienie wydane przez organ właściwej jednostki dozoru technicznego",
    b: "eksploatujący urządzenie techniczne posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    c: "konserwator posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    d: "w niewielkim zakresie kompetentny operator",
    answer: "a"
  },
  {
    title: "Ustawa o dozorze technicznym określa następujące formy dozoru technicznego:",
    a: "całkowita, częściowa, ograniczona",
    b: "pełna, ograniczona, uproszczona",
    c: "pełna, cykliczna, sporadyczna",
    d: "UDT, WDT, TDT",
    answer: "b"
  },
  {
    title: "Decyzję zezwalającą na eksploatację urządzenia technicznego wydaje:",
    a: "konserwator po wykonaniu przeglądu z wynikiem pozytywnym",
    b: "organ właściwej jednostki dozoru technicznego lub eksploatujący urządzenie techniczne z upoważnienia organu właściwej jednostki dozoru technicznego",
    c: "organ właściwej jednostki dozoru technicznego lub organ administracji publicznej z upoważnienia organu właściwej jednostki dozoru technicznego",
    d: "organ właściwej jednostki dozoru technicznego",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada ważną decyzję zezwalającą na eksploatację jeżeli wymagają tego odpowiednie przepisy",
    b: "posiada zaświadczenie kwalifikacyjne odpowiedniej kategorii",
    c: "urządzenie posiada aktualny pozytywny wynik przeglądu konserwacyjnego",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada aktualny wpis w dzienniku konserwacji potwierdzający sprawność urządzenia",
    b: "przeszedł odpowiednie szkolenie stanowiskowe",
    c: "urządzenie posiada ważną decyzję zezwalającą na eksploatację",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "W przypadku nieprzestrzegania przez eksploatującego przepisów o dozorze technicznym eksploatujący:",
    a: "otrzymuje pisemne upomnienie",
    b: "otrzymuje zalecenia pokontrolne",
    c: "podlega grzywnie lub karze ograniczenia wolności",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "W przypadku stwierdzenia zagrożenia dla życia lub zdrowia ludzkiego oraz mienia i środowiska inspektor:",
    a: "wydaje decyzję wstrzymującą eksploatację urządzenia technicznego",
    b: "wystawia mandat karny",
    c: "pisemnie poucza eksploatującego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "W przypadku niebezpiecznego uszkodzenia urządzenia technicznego lub nieszczęśliwego wypadku eksploatujący:",
    a: "niezwłocznie powiadamia UDT o zaistniałym zdarzeniu",
    b: "powiadamia producenta urządzenia o przyczynach powstałego zdarzenia",
    c: "niezwłocznie dokonuje naprawy urządzenia i przekazuje do dalszej eksploatacji",
    d: "zgłasza urządzenie do wykonania naprawy",
    answer: "a"
  },
  {
    title: "Zaświadczenie kwalifikacyjne do obsługi może zostać cofnięte przez:",
    a: "organ właściwej jednostki dozoru technicznego",
    b: "eksploatującego urządzenie techniczne",
    c: "inspektora BHP",
    d: "Państwową Inspekcję Pracy",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne nieobjęte dozorem technicznym to:",
    a: "żurawie o udźwigu do 3,2 t",
    b: "wciągniki i wciągarki oraz suwnice",
    c: "wózki jezdniowe podnośnikowe oraz podesty ruchome",
    d: "zawiesia transportowe",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne objęte dozorem technicznym to:",
    a: "przenośniki kabinowe i krzesełkowe",
    b: "układnice magazynowe oraz urządzenia dla osób niepełnosprawnych",
    c: "wyciągi towarowe i wyciągi statków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Niebezpieczne uszkodzenie urządzenia technicznego to:",
    a: "każda usterka UTB",
    b: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nadaje się do częściowej eksploatacji",
    c: "nieprzewidziane uszkodzenie, w wyniku którego nadaje się do eksploatacji tylko przy obniżonych parametrach",
    d: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nie nadaje się do eksploatacji lub jego dalsza eksploatacja stanowi zagrożenie",
    answer: "d"
  },
  {
    title: "Nieszczęśliwy wypadek to:",
    a: "nagłe zdarzenie, które spowodowało obrażenia ciała lub śmierć",
    b: "nagłe zdarzenie, które spowodowało przerwę w pracy",
    c: "nagłe zdarzenie, które skutkuje wyłączeniem urządzenia technicznego z eksploatacji",
    d: "każda usterka UTB spowodowana przyczyną losową",
    answer: "a"
  },
  {
    title: "Podnoszenie i przenoszenie osób przez urządzenie techniczne przeznaczone wyłącznie do transportu ładunków wymaga:",
    a: "uzgodnienia z organem właściwej jednostki dozoru technicznego",
    b: "uzgodnienia z przełożonym",
    c: "uzgodnienia ze służbą BHP",
    d: "jest możliwe przy zachowaniu szczególnej ostrożności i pod nadzorem inspektora",
    answer: "a"
  },
  {
    title: "Odpowiedzialnym za zapewnienie właściwej obsługi i konserwacji urządzenia technicznego jest:",
    a: "eksploatujący urządzenie techniczne",
    b: "organ właściwej jednostki dozoru technicznego",
    c: "Państwowa Inspekcja Pracy",
    d: "inspektor UDT",
    answer: "a"
  },
  {
    title: "Wymagane przepisami prawa przeglądy konserwacyjne wykonuje:",
    a: "osoba posiadająca zaświadczenie kwalifikacyjne do konserwacji",
    b: "pracownik autoryzowanego serwisu producenta urządzenia (pod warunkiem posiadania zaświadczeń kwalifikacyjnych do konserwacji)",
    c: "zakładowe służby utrzymania ruchu",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "Dziennik konserwacji urządzenia technicznego prowadzi:",
    a: "inspektor UDT w księdze rewizyjnej urządzenia",
    b: "uprawniony operator",
    c: "wyznaczony pracownik eksploatującego",
    d: "konserwator urządzenia technicznego",
    answer: "d"
  },
  {
    title: "Badania odbiorcze przeprowadza się dla urządzeń technicznych:",
    a: "w terminach zgodnych z zapisami rozporządzenia w sprawie warunków technicznych dozoru technicznego",
    b: "przed wydaniem pierwszej decyzji zezwalającej na eksploatacje",
    c: "po naprawie urządzenia technicznego",
    d: "po każdej zmianie eksploatującego",
    answer: "b"
  },
  {
    title: "Badania okresowe przeprowadza się dla urządzeń technicznych objętych dozorem:",
    a: "ograniczonym",
    b: "pełnym",
    c: "uproszczonym",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Nieobecność konserwującego na badaniu urządzenia technicznego wymaga min.:",
    a: "wcześniejszego uzgodnienia tego faktu z organem właściwej jednostki dozoru technicznego",
    b: "przedstawienia pisemnego usprawiedliwienia nieobecności konserwatora",
    c: "przedstawienia zwolnienia lekarskiego potwierdzającego niezdolność konserwatora do pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "a"
  },
  {
    title: "Kandydat na obsługującego urządzenie techniczne musi:",
    a: "mieć ukończone 18 lat",
    b: "posiadać przynajmniej wykształcenie zawodowe",
    c: "posiadać przynajmniej 1 rok stażu pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne ma obowiązek przerwać pracę gdy:",
    a: "jego stan fizyczny i psychiczny w dniu pracy jest nieodpowiedni",
    b: "stwierdzi, że dalsza praca urządzeniem stwarza zagrożenie",
    c: "urządzenie jest niesprawne",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzenia mogą być określone:",
    a: "w instrukcji eksploatacji urządzenia",
    b: "w ustawie o dozorze technicznym",
    c: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    d: "odpowiedź a i c jest prawidłowa",
    answer: "d"
  },
  {
    title: "Terminy badań okresowych i doraźnych kontrolnych UTB określone są:",
    a: "w ustawie o dozorze technicznym",
    b: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    c: "w dokumentacji konstrukcyjnej urządzenia",
    d: "w dzienniku konserwacji",
    answer: "b"
  }
];

export const suwnice: IQuestion[] = [
  {
    title: "Urządzenie techniczne objęte dozorem technicznym może być eksploatowane na podstawie:",
    a: "zezwolenia ustnego lub pisemnego wydanego przez upoważnionego konserwatora",
    b: "ważnej decyzji zezwalającej na eksploatację wydanej przez organ właściwej jednostki dozoru technicznego",
    c: "oznaczenia CE umieszczonego na urządzeniu",
    d: "deklaracji zgodności wystawionej przez wytwórcę",
    answer: "b"
  },
  {
    title: "Które z wymienionych czynności nie należą do zakresu obowiązków obsługującego UTB:",
    a: "wykonywanie niewielkich napraw urządzenia w ramach posiadanego wykształcenia i umiejętności",
    b: "przestrzeganie instrukcji eksploatacji w zakresie obsługi UTB",
    c: "zapoznanie się z planem pracy i wielkością przenoszonych ładunków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzeń technicznych:",
    a: "są zawarte w instrukcji eksploatacji urządzenia",
    b: "określa konserwator urządzenia",
    c: "określa w protokole inspektor wykonujący badanie",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Dozorem technicznym nazywamy:",
    a: "określone ustawą działania zmierzające do zapewnienia bezpiecznego funkcjonowania urządzeń technicznych i urządzeń do odzyskiwania par paliwa oraz działania zmierzające do zapewnienia bezpieczeństwa publicznego w tych obszarach",
    b: "instytucje kontrolujące stan techniczny urządzeń",
    c: "Urząd Dozoru Technicznego",
    d: "UDT, WDT, TDT",
    answer: "a"
  },
  {
    title: "Dozór techniczny nad urządzeniami technicznymi wykonuje:",
    a: "Urząd Dozoru Technicznego oraz specjalistyczne jednostki dozoru technicznego",
    b: "Urząd Dozoru Technicznego oraz upoważnione przez UDT organizacje",
    c: "Urząd Dozoru Technicznego i zagraniczne jednostki dozoru technicznego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Zmiana parametrów technicznych lub zmiana konstrukcji urządzenia technicznego traktowana jest jako:",
    a: "modernizacja urządzenia technicznego",
    b: "naprawa urządzenia technicznego",
    c: "usuwanie usterek i innych nieprawidłowości urządzenia technicznego",
    d: "wytworzenie nowego urządzenia",
    answer: "a"
  },
  {
    title: "Uzgodnioną naprawę lub modernizację urządzeń technicznych może wykonać:",
    a: "naprawiający lub modernizujący, który posiada uprawnienie wydane przez organ właściwej jednostki dozoru technicznego",
    b: "eksploatujący urządzenie techniczne posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    c: "konserwator posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    d: "w niewielkim zakresie kompetentny operator",
    answer: "a"
  },
  {
    title: "Ustawa o dozorze technicznym określa następujące formy dozoru technicznego:",
    a: "całkowita, częściowa, ograniczona",
    b: "pełna, ograniczona, uproszczona",
    c: "pełna, cykliczna, sporadyczna",
    d: "UDT, WDT, TDT",
    answer: "b"
  },
  {
    title: "Decyzję zezwalającą na eksploatację urządzenia technicznego wydaje:",
    a: "konserwator po wykonaniu przeglądu z wynikiem pozytywnym",
    b: "organ właściwej jednostki dozoru technicznego lub eksploatujący urządzenie techniczne z upoważnienia organu właściwej jednostki dozoru technicznego",
    c: "organ właściwej jednostki dozoru technicznego lub organ administracji publicznej z upoważnienia organu właściwej jednostki dozoru technicznego",
    d: "organ właściwej jednostki dozoru technicznego",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada ważną decyzję zezwalającą na eksploatację jeżeli wymagają tego odpowiednie przepisy",
    b: "posiada zaświadczenie kwalifikacyjne odpowiedniej kategorii",
    c: "urządzenie posiada aktualny pozytywny wynik przeglądu konserwacyjnego",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada aktualny wpis w dzienniku konserwacji potwierdzający sprawność urządzenia",
    b: "przeszedł odpowiednie szkolenie stanowiskowe",
    c: "urządzenie posiada ważną decyzję zezwalającą na eksploatację",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "W przypadku nieprzestrzegania przez eksploatującego przepisów o dozorze technicznym eksploatujący:",
    a: "otrzymuje pisemne upomnienie",
    b: "otrzymuje zalecenia pokontrolne",
    c: "podlega grzywnie lub karze ograniczenia wolności",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "W przypadku stwierdzenia zagrożenia dla życia lub zdrowia ludzkiego oraz mienia i środowiska inspektor:",
    a: "wydaje decyzję wstrzymującą eksploatację urządzenia technicznego",
    b: "wystawia mandat karny",
    c: "pisemnie poucza eksploatującego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "W przypadku niebezpiecznego uszkodzenia urządzenia technicznego lub nieszczęśliwego wypadku eksploatujący:",
    a: "niezwłocznie powiadamia UDT o zaistniałym zdarzeniu",
    b: "powiadamia producenta urządzenia o przyczynach powstałego zdarzenia",
    c: "niezwłocznie dokonuje naprawy urządzenia i przekazuje do dalszej eksploatacji",
    d: "zgłasza urządzenie do wykonania naprawy",
    answer: "a"
  },
  {
    title: "Zaświadczenie kwalifikacyjne do obsługi może zostać cofnięte przez:",
    a: "organ właściwej jednostki dozoru technicznego",
    b: "eksploatującego urządzenie techniczne",
    c: "inspektora BHP",
    d: "Państwową Inspekcję Pracy",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne nieobjęte dozorem technicznym to:",
    a: "żurawie o udźwigu do 3,2 t",
    b: "wciągniki i wciągarki oraz suwnice",
    c: "wózki jezdniowe podnośnikowe oraz podesty ruchome",
    d: "zawiesia transportowe",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne objęte dozorem technicznym to:",
    a: "przenośniki kabinowe i krzesełkowe",
    b: "układnice magazynowe oraz urządzenia dla osób niepełnosprawnych",
    c: "wyciągi towarowe i wyciągi statków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Niebezpieczne uszkodzenie urządzenia technicznego to:",
    a: "każda usterka UTB",
    b: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nadaje się do częściowej eksploatacji",
    c: "nieprzewidziane uszkodzenie, w wyniku którego nadaje się do eksploatacji tylko przy obniżonych parametrach",
    d: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nie nadaje się do eksploatacji lub jego dalsza eksploatacja stanowi zagrożenie",
    answer: "d"
  },
  {
    title: "Nieszczęśliwy wypadek to:",
    a: "nagłe zdarzenie, które spowodowało obrażenia ciała lub śmierć",
    b: "nagłe zdarzenie, które spowodowało przerwę w pracy",
    c: "nagłe zdarzenie, które skutkuje wyłączeniem urządzenia technicznego z eksploatacji",
    d: "każda usterka UTB spowodowana przyczyną losową",
    answer: "a"
  },
  {
    title: "Podnoszenie i przenoszenie osób przez urządzenie techniczne przeznaczone wyłącznie do transportu ładunków wymaga:",
    a: "uzgodnienia z organem właściwej jednostki dozoru technicznego",
    b: "uzgodnienia z przełożonym",
    c: "uzgodnienia ze służbą BHP",
    d: "jest możliwe przy zachowaniu szczególnej ostrożności i pod nadzorem inspektora",
    answer: "a"
  },
  {
    title: "Odpowiedzialnym za zapewnienie właściwej obsługi i konserwacji urządzenia technicznego jest:",
    a: "eksploatujący urządzenie techniczne",
    b: "organ właściwej jednostki dozoru technicznego",
    c: "Państwowa Inspekcja Pracy",
    d: "inspektor UDT",
    answer: "a"
  },
  {
    title: "Wymagane przepisami prawa przeglądy konserwacyjne wykonuje:",
    a: "osoba posiadająca zaświadczenie kwalifikacyjne do konserwacji",
    b: "pracownik autoryzowanego serwisu producenta urządzenia (pod warunkiem posiadania zaświadczeń kwalifikacyjnych do konserwacji)",
    c: "zakładowe służby utrzymania ruchu",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "Dziennik konserwacji urządzenia technicznego prowadzi:",
    a: "inspektor UDT w księdze rewizyjnej urządzenia",
    b: "uprawniony operator",
    c: "wyznaczony pracownik eksploatującego",
    d: "konserwator urządzenia technicznego",
    answer: "d"
  },
  {
    title: "Badania odbiorcze przeprowadza się dla urządzeń technicznych:",
    a: "w terminach zgodnych z zapisami rozporządzenia w sprawie warunków technicznych dozoru technicznego",
    b: "przed wydaniem pierwszej decyzji zezwalającej na eksploatacje",
    c: "po naprawie urządzenia technicznego",
    d: "po każdej zmianie eksploatującego",
    answer: "b"
  },
  {
    title: "Badania okresowe przeprowadza się dla urządzeń technicznych objętych dozorem:",
    a: "ograniczonym",
    b: "pełnym",
    c: "uproszczonym",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Nieobecność konserwującego na badaniu urządzenia technicznego wymaga min.:",
    a: "wcześniejszego uzgodnienia tego faktu z organem właściwej jednostki dozoru technicznego",
    b: "przedstawienia pisemnego usprawiedliwienia nieobecności konserwatora",
    c: "przedstawienia zwolnienia lekarskiego potwierdzającego niezdolność konserwatora do pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "a"
  },
  {
    title: "Kandydat na obsługującego urządzenie techniczne musi:",
    a: "mieć ukończone 18 lat",
    b: "posiadać przynajmniej wykształcenie zawodowe",
    c: "posiadać przynajmniej 1 rok stażu pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne ma obowiązek przerwać pracę gdy:",
    a: "jego stan fizyczny i psychiczny w dniu pracy jest nieodpowiedni",
    b: "stwierdzi, że dalsza praca urządzeniem stwarza zagrożenie",
    c: "urządzenie jest niesprawne",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzenia mogą być określone:",
    a: "w instrukcji eksploatacji urządzenia",
    b: "w ustawie o dozorze technicznym",
    c: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    d: "odpowiedź a i c jest prawidłowa",
    answer: "d"
  },
  {
    title: "Terminy badań okresowych i doraźnych kontrolnych UTB określone są:",
    a: "w ustawie o dozorze technicznym",
    b: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    c: "w dokumentacji konstrukcyjnej urządzenia",
    d: "w dzienniku konserwacji",
    answer: "b"
  }
];

export const dzwigi: IQuestion[] = [
  {
    title: "Urządzenie techniczne objęte dozorem technicznym może być eksploatowane na podstawie:",
    a: "zezwolenia ustnego lub pisemnego wydanego przez upoważnionego konserwatora",
    b: "ważnej decyzji zezwalającej na eksploatację wydanej przez organ właściwej jednostki dozoru technicznego",
    c: "oznaczenia CE umieszczonego na urządzeniu",
    d: "deklaracji zgodności wystawionej przez wytwórcę",
    answer: "b"
  },
  {
    title: "Które z wymienionych czynności nie należą do zakresu obowiązków obsługującego UTB:",
    a: "wykonywanie niewielkich napraw urządzenia w ramach posiadanego wykształcenia i umiejętności",
    b: "przestrzeganie instrukcji eksploatacji w zakresie obsługi UTB",
    c: "zapoznanie się z planem pracy i wielkością przenoszonych ładunków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzeń technicznych:",
    a: "są zawarte w instrukcji eksploatacji urządzenia",
    b: "określa konserwator urządzenia",
    c: "określa w protokole inspektor wykonujący badanie",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Dozorem technicznym nazywamy:",
    a: "określone ustawą działania zmierzające do zapewnienia bezpiecznego funkcjonowania urządzeń technicznych i urządzeń do odzyskiwania par paliwa oraz działania zmierzające do zapewnienia bezpieczeństwa publicznego w tych obszarach",
    b: "instytucje kontrolujące stan techniczny urządzeń",
    c: "Urząd Dozoru Technicznego",
    d: "UDT, WDT, TDT",
    answer: "a"
  },
  {
    title: "Dozór techniczny nad urządzeniami technicznymi wykonuje:",
    a: "Urząd Dozoru Technicznego oraz specjalistyczne jednostki dozoru technicznego",
    b: "Urząd Dozoru Technicznego oraz upoważnione przez UDT organizacje",
    c: "Urząd Dozoru Technicznego i zagraniczne jednostki dozoru technicznego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "Zmiana parametrów technicznych lub zmiana konstrukcji urządzenia technicznego traktowana jest jako:",
    a: "modernizacja urządzenia technicznego",
    b: "naprawa urządzenia technicznego",
    c: "usuwanie usterek i innych nieprawidłowości urządzenia technicznego",
    d: "wytworzenie nowego urządzenia",
    answer: "a"
  },
  {
    title: "Uzgodnioną naprawę lub modernizację urządzeń technicznych może wykonać:",
    a: "naprawiający lub modernizujący, który posiada uprawnienie wydane przez organ właściwej jednostki dozoru technicznego",
    b: "eksploatujący urządzenie techniczne posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    c: "konserwator posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    d: "w niewielkim zakresie kompetentny operator",
    answer: "a"
  },
  {
    title: "Ustawa o dozorze technicznym określa następujące formy dozoru technicznego:",
    a: "całkowita, częściowa, ograniczona",
    b: "pełna, ograniczona, uproszczona",
    c: "pełna, cykliczna, sporadyczna",
    d: "UDT,WDT,TDT",
    answer: "b"
  },
  {
    title: "Decyzję zezwalającą na eksploatację urządzenia technicznego wydaje:",
    a: "konserwator po wykonaniu przeglądu z wynikiem pozytywnym",
    b: "organ właściwej jednostki dozoru technicznego lub eksploatujący urządzenie techniczne z upoważnienia organu właściwej jednostki dozoru technicznego",
    c: "organ właściwej jednostki dozoru technicznego lub organ administracji publicznej z upoważnienia organu właściwej jednostki dozoru technicznego",
    d: "organ właściwej jednostki dozoru technicznego",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada ważną decyzję zezwalającą na eksploatację jeżeli wymagają tego odpowiednie przepisy",
    b: "posiada zaświadczenie kwalifikacyjne odpowiedniej kategorii",
    c: "urządzenie posiada aktualny pozytywny wynik przeglądu konserwacyjnego",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada aktualny wpis w dzienniku konserwacji potwierdzający sprawność urządzenia",
    b: "przeszedł odpowiednie szkolenie stanowiskowe",
    c: "urządzenie posiada ważną decyzję zezwalającą na eksploatację",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "W przypadku nieprzestrzegania przez eksploatującego przepisów o dozorze technicznym eksploatujący:",
    a: "otrzymuje pisemne upomnienie",
    b: "otrzymuje zalecenia pokontrolne",
    c: "podlega grzywnie lub karze ograniczenia wolności",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "W przypadku stwierdzenia zagrożenia dla życia lub zdrowia ludzkiego oraz mienia i środowiska inspektor:",
    a: "wydaje decyzję wstrzymującą eksploatację urządzenia technicznego",
    b: "wystawia mandat karny",
    c: "pisemnie poucza eksploatującego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "W przypadku niebezpiecznego uszkodzenia urządzenia technicznego lub nieszczęśliwego wypadku eksploatujący:",
    a: "niezwłocznie powiadamia UDT o zaistniałym zdarzeniu",
    b: "powiadamia producenta urządzenia o przyczynach powstałego zdarzenia",
    c: "niezwłocznie dokonuje naprawy urządzenia i przekazuje do dalszej eksploatacji",
    d: "zgłasza urządzenie do wykonania naprawy",
    answer: "a"
  },
  {
    title: "Zaświadczenie kwalifikacyjne do obsługi może zostać cofnięte przez:",
    a: "organ właściwej jednostki dozoru technicznego",
    b: "eksploatującego urządzenie techniczne",
    c: "inspektora bhp",
    d: "Państwową Inspekcję Pracy",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne nieobjęte dozorem technicznym to:",
    a: "żurawie o udźwigu do 3,2 t",
    b: "wciągniki i wciągarki oraz suwnice",
    c: "wózki jezdniowe podnośnikowe oraz podesty ruchome",
    d: "zawiesia transportowe",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne objęte dozorem technicznym to:",
    a: "przenośniki kabinowe i krzesełkowe",
    b: "układnice magazynowe oraz urządzenia dla osób niepełnosprawnych",
    c: "wyciągi towarowe i wyciągi statków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Niebezpieczne uszkodzenie urządzenia technicznego to:",
    a: "każda usterka UTB",
    b: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nadaje się do częściowej eksploatacji",
    c: "nieprzewidziane uszkodzenie, w wyniku którego nadaje się do eksploatacji tylko przy obniżonych parametrach",
    d: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nie nadaje się do eksploatacji lub jego dalsza eksploatacja stanowi zagrożenie",
    answer: "d"
  },
  {
    title: "Nieszczęśliwy wypadek to:",
    a: "nagłe zdarzenie, które spowodowało obrażenia ciała lub śmierć",
    b: "nagłe zdarzenie, które spowodowało przerwę w pracy",
    c: "nagłe zdarzenie, które skutkuje wyłączeniem urządzenia technicznego z eksploatacji",
    d: "każda usterka UTB spowodowana przyczyną losową",
    answer: "a"
  },
  {
    title: "Odpowiedzialnym za zapewnienie właściwej obsługi i konserwacji urządzenia technicznego jest:",
    a: "eksploatujący urządzenie techniczne",
    b: "organ właściwej jednostki dozoru technicznego",
    c: "Państwowa Inspekcja Pracy",
    d: "inspektor UDT",
    answer: "a"
  },
  {
    title: "Wymagane przepisami prawa przeglądy konserwacyjne wykonuje:",
    a: "osoba posiadająca zaświadczenie kwalifikacyjne do konserwacji",
    b: "pracownik autoryzowanego serwisu producenta urządzenia (pod warunkiem posiadania zaświadczeń kwalifikacyjnych do konserwacji)",
    c: "zakładowe służby utrzymania ruchu",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "Dziennik konserwacji urządzenia technicznego prowadzi:",
    a: "inspektor UDT w księdze rewizyjnej urządzenia",
    b: "uprawniony operator",
    c: "wyznaczony pracownik eksploatującego",
    d: "konserwator urządzenia technicznego",
    answer: "d"
  },
  {
    title: "Badania odbiorcze przeprowadza się dla urządzeń technicznych:",
    a: "w terminach zgodnych z zapisami rozporządzenia w sprawie warunków technicznych dozoru technicznego",
    b: "przed wydaniem pierwszej decyzji zezwalającej na eksploatacje",
    c: "po naprawie urządzenia technicznego",
    d: "po każdej zmianie eksploatującego",
    answer: "b"
  },
  {
    title: "Badania okresowe przeprowadza się dla urządzeń technicznych objętych dozorem:",
    a: "ograniczonym",
    b: "pełnym",
    c: "uproszczonym",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Nieobecność konserwującego na badaniu urządzenia technicznego wymaga min.:",
    a: "wcześniejszego uzgodnienia tego faktu z organem właściwej jednostki dozoru technicznego",
    b: "przedstawienia pisemnego usprawiedliwienia nieobecności konserwatora",
    c: "przedstawienia zwolnienia lekarskiego potwierdzającego niezdolność konserwatora do pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "a"
  },
  {
    title: "Kandydat na obsługującego urządzenie techniczne musi:",
    a: "mieć ukończone 18 lat",
    b: "posiadać przynajmniej wykształcenie zawodowe",
    c: "posiadać przynajmniej 1 rok stażu pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne ma obowiązek przerwać pracę gdy:",
    a: "jego stan fizyczny i psychiczny w dniu pracy jest nieodpowiedni",
    b: "stwierdzi, że dalsza praca urządzeniem stwarza zagrożenie",
    c: "urządzenie jest niesprawne",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzenia mogą być określone:",
    a: "w instrukcji eksploatacji urządzenia",
    b: "w ustawie o dozorze technicznym",
    c: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    d: "odpowiedź a i c jest prawidłowa",
    answer: "d"
  },
  {
    title: "Terminy badań okresowych i doraźnych kontrolnych UTB określone są:",
    a: "w ustawie o dozorze technicznym",
    b: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    c: "w dokumentacji konstrukcyjnej urządzenia",
    d: "w dzienniku konserwacji",
    answer: "b"
  },
  {
    title: "Obowiązkiem obsługującego urządzenie techniczne jest:",
    a: "przestrzeganie instrukcji eksploatacji w zakresie obsługi urządzenia",
    b: "przestrzeganie instrukcji eksploatacji w zakresie konserwacji urządzenia",
    c: "wykonywanie napraw urządzenia",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  }
];

export const zurawie: IQuestion[] = [
  {
    title: "Urządzenie techniczne objęte dozorem technicznym może być eksploatowane na podstawie:",
    a: "zezwolenia ustnego lub pisemnego wydanego przez upoważnionego konserwatora",
    b: "ważnej decyzji zezwalającej na eksploatację wydanej przez organ właściwej jednostki dozoru technicznego",
    c: "oznaczenia CE umieszczonego na urządzeniu",
    d: "deklaracji zgodności wystawionej przez wytwórcę",
    answer: "b"
  },
  {
    title: "Które z wymienionych czynności nie należą do zakresu obowiązków obsługującego UTB:",
    a: "wykonywanie niewielkich napraw urządzenia w ramach posiadanego wykształcenia i umiejętności",
    b: "przestrzeganie instrukcji eksploatacji w zakresie obsługi UTB",
    c: "zapoznanie się z planem pracy i wielkością przenoszonych ładunków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzeń technicznych:",
    a: "są zawarte w instrukcji eksploatacji urządzenia",
    b: "określa konserwator urządzenia",
    c: "określa w protokole inspektor wykonujący badanie",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Dozorem technicznym nazywamy:",
    a: "określone ustawą działania zmierzające do zapewnienia bezpiecznego funkcjonowania urządzeń technicznych i urządzeń do odzyskiwania par paliwa oraz działania zmierzające do zapewnienia bezpieczeństwa publicznego w tych obszarach",
    b: "instytucje kontrolujące stan techniczny urządzeń",
    c: "Urząd Dozoru Technicznego",
    d: "UDT, WDT, TDT",
    answer: "a"
  },
  {
    title: "Dozór techniczny nad urządzeniami technicznymi wykonuje:",
    a: "Urząd Dozoru Technicznego oraz specjalistyczne jednostki dozoru technicznego",
    b: "Urząd Dozoru Technicznego oraz upoważnione przez UDT organizacje",
    c: "Urząd Dozoru Technicznego i zagraniczne jednostki dozoru technicznego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Zmiana parametrów technicznych lub zmiana konstrukcji urządzenia technicznego traktowana jest jako:",
    a: "modernizacja urządzenia technicznego",
    b: "naprawa urządzenia technicznego",
    c: "usuwanie usterek i innych nieprawidłowości urządzenia technicznego",
    d: "wytworzenie nowego urządzenia",
    answer: "a"
  },
  {
    title: "Uzgodnioną naprawę lub modernizację urządzeń technicznych może wykonać:",
    a: "naprawiający lub modernizujący, który posiada uprawnienie wydane przez organ właściwej jednostki dozoru technicznego",
    b: "eksploatujący urządzenie techniczne posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    c: "konserwator posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
    d: "w niewielkim zakresie kompetentny operator",
    answer: "a"
  },
  {
    title: "Ustawa o dozorze technicznym określa następujące formy dozoru technicznego:",
    a: "całkowita, częściowa, ograniczona",
    b: "pełna, ograniczona, uproszczona",
    c: "pełna, cykliczna, sporadyczna",
    d: "UDT, WDT, TDT",
    answer: "b"
  },
  {
    title: "Decyzję zezwalającą na eksploatację urządzenia technicznego wydaje:",
    a: "konserwator po wykonaniu przeglądu z wynikiem pozytywnym",
    b: "organ właściwej jednostki dozoru technicznego lub eksploatujący urządzenie techniczne z upoważnienia organu właściwej jednostki dozoru technicznego",
    c: "organ właściwej jednostki dozoru technicznego lub organ administracji publicznej z upoważnienia organu właściwej jednostki dozoru technicznego",
    d: "organ właściwej jednostki dozoru technicznego",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada ważną decyzję zezwalającą na eksploatację jeżeli wymagają tego odpowiednie przepisy",
    b: "posiada zaświadczenie kwalifikacyjne odpowiedniej kategorii",
    c: "urządzenie posiada aktualny pozytywny wynik przeglądu konserwacyjnego",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
    a: "urządzenie posiada aktualny wpis w dzienniku konserwacji potwierdzający sprawność urządzenia",
    b: "przeszedł odpowiednie szkolenie stanowiskowe",
    c: "urządzenie posiada ważną decyzję zezwalającą na eksploatację",
    d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
    answer: "d"
  },
  {
    title: "W przypadku nieprzestrzegania przez eksploatującego przepisów o dozorze technicznym eksploatujący:",
    a: "otrzymuje pisemne upomnienie",
    b: "otrzymuje zalecenia pokontrolne",
    c: "podlega grzywnie lub karze ograniczenia wolności",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "W przypadku stwierdzenia zagrożenia dla życia lub zdrowia ludzkiego oraz mienia i środowiska inspektor:",
    a: "wydaje decyzję wstrzymującą eksploatację urządzenia technicznego",
    b: "wystawia mandat karny",
    c: "pisemnie poucza eksploatującego",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "a"
  },
  {
    title: "W przypadku niebezpiecznego uszkodzenia urządzenia technicznego lub nieszczęśliwego wypadku eksploatujący:",
    a: "niezwłocznie powiadamia UDT o zaistniałym zdarzeniu",
    b: "powiadamia producenta urządzenia o przyczynach powstałego zdarzenia",
    c: "niezwłocznie dokonuje naprawy urządzenia i przekazuje do dalszej eksploatacji",
    d: "zgłasza urządzenie do wykonania naprawy",
    answer: "a"
  },
  {
    title: "Zaświadczenie kwalifikacyjne do obsługi może zostać cofnięte przez:",
    a: "organ właściwej jednostki dozoru technicznego",
    b: "eksploatującego urządzenie techniczne",
    c: "inspektora bhp",
    d: "Państwową Inspekcję Pracy",
    answer: "a"
  },
  {
    title: "Urządzenia techniczne nieobjęte dozorem technicznym to:",
    a: "żurawie o udźwigu do 3,2 t",
    b: "wciągniki i wciągarki oraz suwnice",
    c: "wózki jezdniowe podnośnikowe oraz podesty ruchome",
    d: "zawiesia transportowe",
    answer: "d"
  },
  {
    title: "Urządzenia techniczne objęte dozorem technicznym to:",
    a: "przenośniki kabinowe i krzesełkowe",
    b: "układnice magazynowe oraz urządzenia dla osób niepełnosprawnych",
    c: "wyciągi towarowe i wyciągi statków",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Niebezpieczne uszkodzenie urządzenia technicznego to:",
    a: "każda usterka UTB",
    b: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nadaje się do częściowej eksploatacji",
    c: "nieprzewidziane uszkodzenie, w wyniku którego nadaje się do eksploatacji tylko przy obniżonych parametrach",
    d: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nie nadaje się do eksploatacji lub jego dalsza eksploatacja stanowi zagrożenie",
    answer: "d"
  },
  {
    title: "Nieszczęśliwy wypadek to:",
    a: "nagłe zdarzenie, które spowodowało obrażenia ciała lub śmierć",
    b: "nagłe zdarzenie, które spowodowało przerwę w pracy",
    c: "nagłe zdarzenie, które skutkuje wyłączeniem urządzenia technicznego z eksploatacji",
    d: "każda usterka UTB spowodowana przyczyną losową",
    answer: "a"
  },
  {
    title: "Podnoszenie i przenoszenie osób przez urządzenie techniczne przeznaczone wyłącznie do transportu ładunków wymaga:",
    a: "uzgodnienia z organem właściwej jednostki dozoru technicznego",
    b: "uzgodnienia z przełożonym",
    c: "uzgodnienia ze służbą BHP",
    d: "jest możliwe przy zachowaniu szczególnej ostrożności i pod nadzorem inspektora",
    answer: "a"
  },
  {
    title: "Odpowiedzialnym za zapewnienie właściwej obsługi i konserwacji urządzenia technicznego jest:",
    a: "eksploatujący urządzenie techniczne",
    b: "organ właściwej jednostki dozoru technicznego",
    c: "Państwowa Inspekcja Pracy",
    d: "inspektor UDT",
    answer: "a"
  },
  {
    title: "Wymagane przepisami prawa przeglądy konserwacyjne wykonuje:",
    a: "osoba posiadająca zaświadczenie kwalifikacyjne do konserwacji",
    b: "pracownik autoryzowanego serwisu producenta urządzenia (pod warunkiem posiadania zaświadczeń kwalifikacyjnych do konserwacji)",
    c: "zakładowe służby utrzymania ruchu",
    d: "odpowiedź a i b jest prawidłowa",
    answer: "d"
  },
  {
    title: "Dziennik konserwacji urządzenia technicznego prowadzi:",
    a: "inspektor UDT w księdze rewizyjnej urządzenia",
    b: "uprawniony operator",
    c: "wyznaczony pracownik eksploatującego",
    d: "konserwator urządzenia technicznego",
    answer: "d"
  },
  {
    title: "Badania odbiorcze przeprowadza się dla urządzeń technicznych:",
    a: "w terminach zgodnych z zapisami rozporządzenia w sprawie warunków technicznych dozoru technicznego",
    b: "przed wydaniem pierwszej decyzji zezwalającej na eksploatacje",
    c: "po naprawie urządzenia technicznego",
    d: "po każdej zmianie eksploatującego",
    answer: "b"
  },
  {
    title: "Badania okresowe przeprowadza się dla urządzeń technicznych objętych dozorem:",
    a: "ograniczonym",
    b: "pełnym",
    c: "uproszczonym",
    d: "wszystkie odpowiedzi są poprawne",
    answer: "d"
  },
  {
    title: "Nieobecność konserwującego na badaniu urządzenia technicznego wymaga min.:",
    a: "wcześniejszego uzgodnienia tego faktu z organem właściwej jednostki dozoru technicznego",
    b: "przedstawienia pisemnego usprawiedliwienia nieobecności konserwatora",
    c: "przedstawienia zwolnienia lekarskiego potwierdzającego niezdolność konserwatora do pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Kandydat na obsługującego urządzenie techniczne musi:",
    a: "mieć ukończone 18 lat",
    b: "posiadać przynajmniej wykształcenie zawodowe",
    c: "posiadać przynajmniej 1 rok stażu pracy",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Obsługujący urządzenie techniczne ma obowiązek przerwać pracę gdy:",
    a: "jego stan fizyczny i psychiczny w dniu pracy jest nieodpowiedni",
    b: "stwierdzi, że dalsza praca urządzeniem stwarza zagrożenie",
    c: "urządzenie jest niesprawne",
    d: "wszystkie odpowiedzi są prawidłowe",
    answer: "d"
  },
  {
    title: "Terminy przeglądów konserwacyjnych urządzenia mogą być określone:",
    a: "w instrukcji eksploatacji urządzenia",
    b: "w ustawie o dozorze technicznym",
    c: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    d: "odpowiedź a i c jest prawidłowa",
    answer: "d"
  },
  {
    title: "Terminy badań okresowych i doraźnych kontrolnych UTB określone są:",
    a: "w ustawie o dozorze technicznym",
    b: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
    c: "w dokumentacji konstrukcyjnej urządzenia",
    d: "w dzienniku konserwacji",
    answer: "b"
  }
];

export const podesty: IQuestion[] = [
    {
      title: "Urządzenie techniczne objęte dozorem technicznym może być eksploatowane na podstawie:",
      a: "zezwolenia ustnego lub pisemnego wydanego przez upoważnionego konserwatora",
      b: "ważnej decyzji zezwalającej na eksploatację wydanej przez organ właściwej jednostki dozoru technicznego",
      c: "oznaczenia CE umieszczonego na urządzeniu",
      d: "deklaracji zgodności wystawionej przez wytwórcę",
      answer: "b"
    },
    {
      title: "Które z wymienionych czynności nie należą do zakresu obowiązków obsługującego UTB:",
      a: "wykonywanie niewielkich napraw urządzenia w ramach posiadanego wykształcenia i umiejętności",
      b: "przestrzeganie instrukcji eksploatacji w zakresie obsługi UTB",
      c: "zapoznanie się z planem pracy i wielkością przenoszonych ładunków",
      d: "wszystkie odpowiedzi są poprawne",
      answer: "a"
    },
    {
      title: "Terminy przeglądów konserwacyjnych urządzeń technicznych:",
      a: "są zawarte w instrukcji eksploatacji urządzenia",
      b: "określa konserwator urządzenia",
      c: "określa w protokole inspektor wykonujący badanie",
      d: "wszystkie odpowiedzi są poprawne",
      answer: "a"
    },
    {
      title: "Dozorem technicznym nazywamy:",
      a: "określone ustawą działania zmierzające do zapewnienia bezpiecznego funkcjonowania urządzeń technicznych i urządzeń do odzyskiwania par paliwa oraz działania zmierzające do zapewnienia bezpieczeństwa publicznego w tych obszarach",
      b: "instytucje kontrolujące stan techniczny urządzeń",
      c: "Urząd Dozoru Technicznego",
      d: "UDT, WDT, TDT",
      answer: "a"
    },
    {
      title: "Dozór techniczny nad urządzeniami technicznymi wykonuje:",
      a: "Urząd Dozoru Technicznego oraz specjalistyczne jednostki dozoru technicznego",
      b: "Urząd Dozoru Technicznego oraz upoważnione przez UDT organizacje",
      c: "Urząd Dozoru Technicznego i zagraniczne jednostki dozoru technicznego",
      d: "wszystkie odpowiedzi są poprawne",
      answer: "a"
    },
    {
      title: "Zmiana parametrów technicznych lub zmiana konstrukcji urządzenia technicznego traktowana jest jako:",
      a: "modernizacja urządzenia technicznego",
      b: "naprawa urządzenia technicznego",
      c: "usuwanie usterek i innych nieprawidłowości urządzenia technicznego",
      d: "wytworzenie nowego urządzenia",
      answer: "a"
    },
    {
      title: "Uzgodnioną naprawę lub modernizację urządzeń technicznych może wykonać:",
      a: "naprawiający lub modernizujący, który posiada uprawnienie wydane przez organ właściwej jednostki dozoru technicznego",
      b: "eksploatujący urządzenie techniczne posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
      c: "konserwator posiadający odpowiednie doświadczenie w zakresie napraw lub modernizacji",
      d: "w niewielkim zakresie kompetentny operator",
      answer: "a"
    },
    {
      title: "Ustawa o dozorze technicznym określa następujące formy dozoru technicznego:",
      a: "całkowita, częściowa, ograniczona",
      b: "pełna, ograniczona, uproszczona",
      c: "pełna, cykliczna, sporadyczna",
      d: "UDT, WDT, TDT",
      answer: "b"
    },
    {
      title: "Decyzję zezwalającą na eksploatację urządzenia technicznego wydaje:",
      a: "konserwator po wykonaniu przeglądu z wynikiem pozytywnym",
      b: "organ właściwej jednostki dozoru technicznego lub eksploatujący urządzenie techniczne z upoważnienia organu właściwej jednostki dozoru technicznego",
      c: "organ właściwej jednostki dozoru technicznego lub organ administracji publicznej z upoważnienia organu właściwej jednostki dozoru technicznego",
      d: "organ właściwej jednostki dozoru technicznego",
      answer: "d"
    },
    {
      title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
      a: "urządzenie posiada ważną decyzję zezwalającą na eksploatację jeżeli wymagają tego odpowiednie przepisy",
      b: "posiada zaświadczenie kwalifikacyjne odpowiedniej kategorii",
      c: "urządzenie posiada aktualny pozytywny wynik przeglądu konserwacyjnego",
      d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
      answer: "d"
    },
    {
      title: "Obsługujący urządzenie techniczne może podjąć pracę gdy:",
      a: "urządzenie posiada aktualny wpis w dzienniku konserwacji potwierdzający sprawność urządzenia",
      b: "przeszedł odpowiednie szkolenie stanowiskowe",
      c: "urządzenie posiada ważną decyzję zezwalającą na eksploatację",
      d: "wszystkie powyższe warunki muszą być spełnione jednocześnie",
      answer: "d"
    },
    {
      title: "W przypadku nieprzestrzegania przez eksploatującego przepisów o dozorze technicznym eksploatujący:",
      a: "otrzymuje pisemne upomnienie",
      b: "otrzymuje zalecenia pokontrolne",
      c: "podlega grzywnie lub karze ograniczenia wolności",
      d: "odpowiedź a i b jest prawidłowa",
      answer: "d"
    },
    {
      title: "W przypadku stwierdzenia zagrożenia dla życia lub zdrowia ludzkiego oraz mienia i środowiska inspektor:",
      a: "wydaje decyzję wstrzymującą eksploatację urządzenia technicznego",
      b: "wystawia mandat karny",
      c: "pisemnie poucza eksploatującego",
      d: "wszystkie odpowiedzi są poprawne",
      answer: "a"
    },
    {
      title: "W przypadku niebezpiecznego uszkodzenia urządzenia technicznego lub nieszczęśliwego wypadku eksploatujący:",
      a: "niezwłocznie powiadamia UDT o zaistniałym zdarzeniu",
      b: "powiadamia producenta urządzenia o przyczynach powstałego zdarzenia",
      c: "niezwłocznie dokonuje naprawy urządzenia i przekazuje do dalszej eksploatacji",
      d: "zgłasza urządzenie do wykonania naprawy",
      answer: "a"
    },
    {
      title: "Zaświadczenie kwalifikacyjne do obsługi może zostać cofnięte przez:",
      a: "organ właściwej jednostki dozoru technicznego",
      b: "eksploatującego urządzenie techniczne",
      c: "inspektora BHP",
      d: "Państwową Inspekcję Pracy",
      answer: "a"
    },
    {
        title: "Urządzenia techniczne nieobjęte dozorem technicznym to:",
        a: "żurawie o udźwigu do 3,2 t",
        b: "wciągniki i wciągarki oraz suwnice",
        c: "wózki jezdniowe podnośnikowe oraz podesty ruchome",
        d: "zawiesia transportowe",
        answer: "a"
    },
    {
        title: "Urządzenia techniczne objęte dozorem technicznym to:",
        a: "przenośniki kabinowe i krzesełkowe",
        b: "układnice magazynowe oraz urządzenia dla osób niepełnosprawnych",
        c: "wyciągi towarowe i wyciągi statków",
        d: "wszystkie odpowiedzi są poprawne",
        answer: "d"
    },
    {
        title: "Niebezpieczne uszkodzenie urządzenia technicznego to:",
        a: "każda usterka UTB",
        b: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nadaje się do częściowej eksploatacji",
        c: "nieprzewidziane uszkodzenie, w wyniku którego nadaje się do eksploatacji tylko przy obniżonych parametrach",
        d: "nieprzewidziane uszkodzenie, w wyniku którego urządzenie nie nadaje się do eksploatacji lub jego dalsza eksploatacja stanowi zagrożenie",
        answer: "d"
    },
    {
        title: "Nieszczęśliwy wypadek to:",
        a: "nagłe zdarzenie, które spowodowało obrażenia ciała lub śmierć",
        b: "nagłe zdarzenie, które spowodowało przerwę w pracy",
        c: "nagłe zdarzenie, które skutkuje wyłączeniem urządzenia technicznego z eksploatacji",
        d: "każda usterka UTB spowodowana przyczyną losową",
        answer: "a"
    },
    {
        title: "Odpowiedzialnym za zapewnienie właściwej obsługi i konserwacji urządzenia technicznego jest:",
        a: "eksploatujący urządzenie techniczne",
        b: "organ właściwej jednostki dozoru technicznego",
        c: "Państwowa Inspekcja Pracy",
        d: "inspektor UDT",
        answer: "a"
    },
    {
        title: "Wymagane przepisami prawa przeglądy konserwacyjne wykonuje:",
        a: "osoba posiadająca zaświadczenie kwalifikacyjne do konserwacji",
        b: "pracownik autoryzowanego serwisu producenta urządzenia (pod warunkiem posiadania zaświadczeń kwalifikacyjnych do konserwacji)",
        c: "zakładowe służby utrzymania ruchu",
        d: "odpowiedź a i b jest prawidłowa",
        answer: "d"
    },
    {
        title: "Dziennik konserwacji urządzenia technicznego prowadzi:",
        a: "inspektor UDT w księdze rewizyjnej urządzenia",
        b: "uprawniony operator",
        c: "wyznaczony pracownik eksploatującego",
        d: "konserwator urządzenia technicznego",
        answer: "d"
    },
    {
        title: "Badania odbiorcze przeprowadza się dla urządzeń technicznych:",
        a: "w terminach zgodnych z zapisami rozporządzenia w sprawie warunków technicznych dozoru technicznego",
        b: "przed wydaniem pierwszej decyzji zezwalającej na eksploatacje",
        c: "po naprawie urządzenia technicznego",
        d: "po każdej zmianie eksploatującego",
        answer: "b"
    },
    {
        title: "Badania okresowe przeprowadza się dla urządzeń technicznych objętych dozorem:",
        a: "ograniczonym",
        b: "pełnym",
        c: "uproszczonym",
        d: "wszystkie odpowiedzi są poprawne",
        answer: "d"
    },
    {
        title: "Nieobecność konserwującego na badaniu urządzenia technicznego wymaga min.:",
        a: "wcześniejszego uzgodnienia tego faktu z organem właściwej jednostki dozoru technicznego",
        b: "przedstawienia pisemnego usprawiedliwienia nieobecności konserwatora",
        c: "przedstawienia zwolnienia lekarskiego potwierdzającego niezdolność konserwatora do pracy",
        d: "wszystkie odpowiedzi są prawidłowe",
        answer: "a"
    },
    {
        title: "Kandydat na obsługującego urządzenie techniczne musi:",
        a: "mieć ukończone 18 lat",
        b: "posiadać przynajmniej wykształcenie zawodowe",
        c: "posiadać przynajmniej 1 rok stażu pracy",
        d: "wszystkie odpowiedzi są prawidłowe",
        answer: "d"
    },
    {
        title: "Obsługujący urządzenie techniczne ma obowiązek przerwać pracę gdy:",
        a: "jego stan fizyczny i psychiczny w dniu pracy jest nieodpowiedni",
        b: "stwierdzi, że dalsza praca urządzeniem stwarza zagrożenie",
        c: "urządzenie jest niesprawne",
        d: "wszystkie odpowiedzi są prawidłowe",
        answer: "d"
    },
    {
        title: "Terminy przeglądów konserwacyjnych urządzenia mogą być określone:",
        a: "w instrukcji eksploatacji urządzenia",
        b: "w ustawie o dozorze technicznym",
        c: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
        d: "odpowiedź a i c jest prawidłowa",
        answer: "d"
    },
    {
        title: "Terminy badań okresowych i doraźnych kontrolnych UTB określone są:",
        a: "w ustawie o dozorze technicznym",
        b: "w rozporządzeniu określającym warunki techniczne dozoru technicznego",
        c: "w dokumentacji konstrukcyjnej urządzenia",
        d: "w dzienniku konserwacji",
        answer: "b"
    },
    {
        title: "Obowiązkiem obsługującego urządzenie techniczne jest:",
        a: "przestrzeganie instrukcji eksploatacji w zakresie obsługi urządzenia",
        b: "przestrzeganie instrukcji eksploatacji w zakresie konserwacji urządzenia",
        c: "wykonywanie napraw urządzenia",
        d: "wszystkie odpowiedzi są poprawne",
        answer: "a"
    }
];