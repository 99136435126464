import React from "react";
import "./Heading.scss";
import { ITextElement } from "../../../types/ITextElement";

export default function Heading({ id, type, className, children, onClick }: ITextElement) {
    switch(type){
        case 'h1': return <h1 id={id} className={className} onClick={onClick}>{children}</h1>;
        case 'h2': return <h2 id={id} className={className} onClick={onClick}>{children}</h2>;
        case 'h3': return <h3 id={id} className={className} onClick={onClick}>{children}</h3>;
        case 'h4': return <h4 id={id} className={className} onClick={onClick}>{children}</h4>;
        case 'h5': return <h5 id={id} className={className} onClick={onClick}>{children}</h5>;
        case 'h6': return <h6 id={id} className={className} onClick={onClick}>{children}</h6>;
        default: throw new Error("Wrong type selected!");
    }
}