import React from "react";
import "./List.scss";
import { ITextElement } from "../../../types/ITextElement";

export default function List({ id, type, className, items, onClick }: ITextElement) {
    switch(type){
        case 'ul': return <ul id={id} className={className}>{items.map((item, index) => (<li key={index} className={className+'-li'} onClick={onClick}>{item}</li>))}</ul>
        case 'ol': return <ol id={id} className={className}>{items.map((item, index) => (<li key={index} className={className+'-li'} onClick={onClick}>{item}</li>))}</ol>
        default: throw new Error("Wrong type selected!");
    }
}