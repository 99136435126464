import "./Tests.scss";
import Section from "../BlockElement/Section/Section";
import Container from "../BlockElement/Container/Container";
import { courses } from "../../utils/courses";
import { useDisplay } from "../../hooks/useDisplay";
import { useNavigate } from "react-router-dom";

export default function Tests(){
    const { isMobile } = useDisplay();
    const navigate = useNavigate();
    
    const redirect = (value: string|null) => {
        try{
            if(!value){ window.location.href = 'https://centrumszkolen.net'; return; }
            navigate(`/test/${value}`);
        }catch(error: any){
            console.log("Error redirecting");
        }
    };

    return isMobile ? <Tests.Mobile redirect={redirect} /> : <Tests.Desktop redirect={redirect} />; 
}

Tests.Mobile = (props: { redirect: any }) => {
    return(
        <Section className="mobile--tests">
            <Container className="mobile--tests__container">
                <Container className="mobile--tests__container--nested">
                    {courses.map((element, index) => ( 
                        <Container key={index}>
                            <Container
                                onClick={element.href === "odwiedz-nas" ? () => props.redirect() : () => props.redirect(element.href)}
                                style={{
                                    backgroundImage: `url(/assets/imgs/${element.href}.webp)`,
                                    backgroundPosition: 'center',
                                    backgroundSize: '110%',
                                }}
                            ></Container>
                            <Container>{element.title}</Container>
                        </Container>
                    ))}
                </Container>
            </Container>
            <Triangles />
        </Section>
    );
}

Tests.Desktop = (props: { redirect: any }) => {
    return(
        <Section className="desktop--tests">
            <Container className="desktop--tests__container">
                <Container className="desktop--tests__container--nested">
                    {courses.map((element, index) => ( 
                        <Container key={index}>
                            <Container
                                onClick={element.href === "odwiedz-nas" ? () => props.redirect() : () => props.redirect(element.href)}
                                style={{
                                    backgroundImage: `url(/assets/imgs/${element.href}.webp)`,
                                    backgroundPosition: 'center',
                                    backgroundSize: '110%',
                                }}
                            ></Container>
                            <Container>{element.title}</Container>
                        </Container>
                    ))}
                </Container>
            </Container>
            <Triangles />
        </Section>
    );
}

function Triangles(){
    return(
        <>
            <svg
                width="1111"
                height="1111"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ left: '-300px', top: '-500px' }}
            >
                <path
                    d="M1.5 4.5v6l6 3.5 6-3.5v-6L7.5 1z"
                    stroke="rgba(255, 0, 0, 0.1)"
                    transform="rotate(-90 7.5 7.5)"
                ></path>
            </svg>
            <svg
                width="1111"
                height="1111"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ left: '-750px', top: '-500px' }}
            >
                <path
                    d="M1.5 4.5v6l6 3.5 6-3.5v-6L7.5 1z"
                    stroke="rgba(250, 215, 51, 0.15)"
                    transform="rotate(-90 7.5 7.5)"
                ></path>
            </svg>
            <svg
                width="1111"
                height="1111"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ left: '550px', top: '300px' }}
            >
                <path
                    d="M1.5 4.5v6l6 3.5 6-3.5v-6L7.5 1z"
                    stroke="rgba(0, 101, 255, 0.1)"
                    transform="rotate(-90 7.5 7.5)"
                ></path>
            </svg>
            <svg
                width="1111"
                height="1111"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ left: '30px', top: '-180px' }}
            >
                <path
                    d="M1.5 4.5v6l6 3.5 6-3.5v-6L7.5 1z"
                    stroke="rgba(22, 169, 88, 0.1)"
                    transform="rotate(-90 7.5 7.5)"
                ></path>
            </svg>
        </>
    );
}