import React from "react";
import "./Footbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Footer from "../../BlockElement/Footer/Footer";

export default function Footbar({ id, className }: IStructureElement) {
    return(
        <Footer id={id} className={className}>

        </Footer>
    );  //TODO
}