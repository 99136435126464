import { useEffect, useState } from "react";
import { Modal } from 'antd';
import { courses } from "../utils/courses";
import { ICourse } from "../types/ICourse";
import { useNavigate } from "react-router-dom";
import { IQuestion } from "../types/IQuestion";

export const useTest = (href:string|undefined) => {
    // Course related vars
    const course: ICourse|undefined = courses.find(c => c.href === href);
    if (!course) { throw new Error(`Course with href "${href}" not found!`) }; 
    const navigate = useNavigate();
    
    // Time related vars
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [time, setTime] = useState<number>(30 * 60);
    const minutes: number = Math.floor(time / 60);
    const seconds: number = time % 60;
    const formattedTime: string = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    // Game related vars
    const [score, setScore] = useState<number>(0);
    const [counter, setCounter] = useState<number>(1);
    const [highlightedAnswer, setHighlightedAnswer] = useState<string | null>(null);
    const question: IQuestion = {
        id: counter,
        title: course.set[counter].title,
        a: course.set[counter].a,
        b: course.set[counter].b,
        c: course.set[counter].c,
        d: course.set[counter].d,
        answer: course.set[counter].answer
    };

    // Clock counter
    useEffect(() => {
        if (time <= 0 || !isRunning) return;
    
        const intervalId = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);
    
        return () => clearInterval(intervalId);
    }, [isRunning]);

    const reset = (state: boolean) => {
        try{
            course.set = [...course.set].sort(() => Math.random() - 0.5); 
            setScore(0);    // reseting score
            setCounter(1);  // reseting round counter
            setTime(30 * 60);   // reseting clock counter
            setIsRunning(state); // starting or stopping the game
        } catch(error:any){
            console.log("Error during reset!", error);
        }
    };

    const verify = async (value: string) => {
        try{
            if(value === question.answer){ setScore((prevCounter) => prevCounter + 1); }
            setHighlightedAnswer(question.answer);
        } catch(error:any){
            console.log("Error during verification!", error);
        }
    };

    const next = () => {
        try{
            if(counter >= 15){ return conclude(); }
            setCounter((prevCounter) => prevCounter + 1);
            setHighlightedAnswer(null);
        } catch(error:any){
            console.log("Error during next!", error);
        }
    };

    const conclude = () => {
        try{
            reset(false);
            return score < 11 ? failure() : success(); 
        } catch(error:any){
            console.log("Error during conclusion!", error);
        }
    };

    const failure = () => {
        Modal.error({
            title: 'Niestety :(',
            content: `Twój wynik to ${score}/15. Nie udało ci się zaliczyć egzaminu. Spróbuj ponownie!`,
        });
    };

    const success = () => {
        Modal.success({
            title: 'Brawo!',
            content: `Twój wynik to ${score}/15. Udało ci się zaliczyć egzaminu. Powodzenia!`,
        });
    };
    
    // Test handler
    const test = {
        start: () => reset(true),
        stop: () => conclude(),
        verify: (value: string) => verify(value),
        next: () => next(),
        conclude: () => conclude(),
        return: () => navigate("/"),
        course: course,
        time: formattedTime, 
        isRunning: isRunning,
        question: question,
        highlight: highlightedAnswer
    };

    return { test };
};