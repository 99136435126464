import "./Test.scss";
import Page from "../../components/StructureElement/Page/Page";
import {  useParams } from "react-router-dom";
import Brief from "../../components/Brief/Brief";
import Question from "../../components/Question/Question";
import { useTest } from "../../hooks/useTest";

export default function Test(){
    const { href } = useParams();
    const { test } = useTest(href);

    return(
        <Page>
            <Brief test = {test} />
            {test.isRunning && <Question test={test}/>}
        </Page>
    ); 
}