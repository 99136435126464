import { ICourse } from "../types/ICourse";
import { dzwigi, iiwjo, iwjo, podesty, suwnice, zurawie } from "./questions";

export const courses:ICourse[] = [
    { title: 'Wózki widłowe (I WJO)', href: 'kursy-na-wozki-widlowe', set: iwjo },
    { title: 'Wózki widłowe (II WJO)', href: 'kursy-na-ladowarki-teleskopowe', set: iiwjo },
    { title: 'Suwnice i wciągarki', href: 'kursy-na-suwnice', set: suwnice },
    { title: 'Podesty ruchome', href: 'kursy-na-podesty-ruchome', set: podesty },
    { title: 'HDS', href: 'kursy-na-hds', set: zurawie },
    { title: 'Dźwigi i windy', href: 'kursy-na-dzwigi-i-windy', set: dzwigi },
    { title: 'Odwiedź naszą stronę', href: 'odwiedz-nas', set: podesty },
];