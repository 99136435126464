import React from "react";
import "./Navbar.scss";
import { IStructureElement } from "../../../types/IStructureElement";
import Nav from "../../BlockElement/Nav/Nav";

export default function Navbar({ id, className }: IStructureElement) {
    return(
        <Nav id={id} className={className}>
            
        </Nav>
    );
}