import { useDisplay } from "../../hooks/useDisplay";
import { rules } from "../../utils/rules";
import Container from "../BlockElement/Container/Container";
import Section from "../BlockElement/Section/Section";
import Button from "../FormElement/Button/Button";
import Heading from "../TextElement/Heading/Heading";
import Hr from "../TextElement/Hr/Hr";
import List from "../TextElement/List/List";
import "./Brief.scss";

export default function Brief(props: any){
    const { isMobile } = useDisplay();
    return isMobile ? <Brief.Mobile test={props.test} /> : <Brief.Desktop test={props.test}  />; 
}

Brief.Mobile = (props: any) => {
    const test = props.test;

    return(
        <Section className="mobile--brief">
            <Container className="mobile--brief__container--left">
                <Hr className="mobile--brief__hr" items={[]} htmlFor={""}/>
                <Heading type="h1" className="" items={[]} htmlFor={""}>{test.course.title}</Heading>
                <List type="ul" className="" items={rules} htmlFor={""} />
                <Container className="mobile--brief__container--buttons">
                    <Button className="mobile--return" onClick={() => test.return()}>Powrót</Button>
                    <Button className="mobile--primary" onClick={() => (test.isRunning ? test.stop() : test.start())}>{test.isRunning ? "Zakończ test" : "Rozpocznij test"}</Button>
                    {test.isRunning && <Button className="mobile--timer">{`Czas ${test.time}`}</Button>}
                </Container>
            </Container>
            <Container className="mobile--brief__container--right"></Container>
        </Section>
    );
}

Brief.Desktop = (props: any) => {
    const test = props.test;

    return(
        <Section className="desktop--brief">
            <Container className="desktop--brief__container--left">
                <Hr className="desktop--brief__hr" items={[]} htmlFor={""}/>
                <Heading type="h1" className="" items={[]} htmlFor={""}>{test.course.title}</Heading>
                <List type="ul" className="" items={rules} htmlFor={""} />
                <Container className="desktop--brief__container--buttons">
                    <Button className="desktop--return" onClick={() => test.return()}>Powrót</Button>
                    <Button className="desktop--primary" onClick={() => (test.isRunning ? test.stop() : test.start())}>{test.isRunning ? "Zakończ test" : "Rozpocznij test"}</Button>
                    {test.isRunning && <Button className="desktop--timer">{`Czas ${test.time}`}</Button>}
                </Container>
            </Container>
            <Container className="desktop--brief__container--right"></Container>
        </Section>
    );
}