import "./Headline.scss";
import Header from "../BlockElement/Header/Header";
import Heading from "../TextElement/Heading/Heading";
import Paragraph from "../TextElement/Paragraph/Paragraph";
import { IHeadline } from "../../types/IHeadline";
import { useDisplay } from "../../hooks/useDisplay";

export default function Headline({title, text}: IHeadline){
    const { isMobile } = useDisplay();
    return isMobile ? <Headline.Mobile title={title} text={text} /> : <Headline.Desktop title={title} text={text} />; 
}

Headline.Mobile = ({title, text}: IHeadline) => {
    return(
        <Header className="mobile--headline">
            <Heading type="h1" className="mobile--headline__h1" items={[]} htmlFor={""}>{title}</Heading>
            <Paragraph className="mobile--headline__paragraph" items={[]} htmlFor={""}>{text}</Paragraph>
        </Header>
    );
}

Headline.Desktop = ({title, text}: IHeadline) => {
    return(
        <Header className="desktop--headline">
            <Heading type="h1" className="desktop--headline__h1" items={[]} htmlFor={""}>{title}</Heading>
            <Paragraph className="desktop--headline__paragraph" items={[]} htmlFor={""}>{text}</Paragraph>
        </Header>
    );
}