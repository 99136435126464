import Container from "../BlockElement/Container/Container";
import Section from "../BlockElement/Section/Section";
import Heading from "../TextElement/Heading/Heading";
import "./Question.scss";
import { useDisplay } from "../../hooks/useDisplay";

export default function Question(props: any){
    const { isMobile } = useDisplay();
    return isMobile ? <Question.Mobile test={props.test} /> : <Question.Desktop test={props.test} />; 
}

Question.Mobile = (props: any) => {
    const test = props.test;

    const onClick = async (value: string) => {
        try{
            await test.verify(value);
            setTimeout(() => { test.next(); }, 5000);
        } catch(error: any){
            console.log("Error while selecting answer!", error);
        }
    };

    const highlight = (value: string) => {
        if (!test.highlight) return "";
        return value === test.highlight ? "highlight-correct" : "highlight-incorrect";
    };

    return (
        <Section className="mobile--question">
            <Heading type="h3" items={[]} htmlFor={""}>{test.question.id + '. ' + test.question.title}</Heading>
            <Container className="mobile--answers">
                <Container className={"mobile--answer " + highlight('a')} onClick={() => onClick('a')}>{'A. ' + test.question.a}</Container>
                <Container className={"mobile--answer " + highlight('b')} onClick={() => onClick('b')}>{'B. ' + test.question.b}</Container>
                <Container className={"mobile--answer " + highlight('c')} onClick={() => onClick('c')}>{'C. ' + test.question.c}</Container>
                <Container className={"mobile--answer " + highlight('d')} onClick={() => onClick('d')}>{'D. ' + test.question.d}</Container>
            </Container>
        </Section>
    );
}

Question.Desktop = (props: any) => {
    const test = props.test;

    const onClick = async (value: string) => {
        try{
            await test.verify(value);
            setTimeout(() => { test.next(); }, 5000);
        } catch(error: any){
            console.log("Error while selecting answer!", error);
        }
    };

    const highlight = (value: string) => {
        if (!test.highlight) return "";
        return value === test.highlight ? "highlight-correct" : "";
    };

    return (
        <Section className="desktop--question">
            <Heading type="h3" items={[]} htmlFor={""}>{test.question.id + '. ' + test.question.title}</Heading>
            <Container className="desktop--answers">
                <Container className={"desktop--answer " + highlight('a')} onClick={() => onClick('a')}>{'A. ' + test.question.a}</Container>
                <Container className={"desktop--answer " + highlight('b')} onClick={() => onClick('b')}>{'B. ' + test.question.b}</Container>
                <Container className={"desktop--answer " + highlight('c')} onClick={() => onClick('c')}>{'C. ' + test.question.c}</Container>
                <Container className={"desktop--answer " + highlight('d')} onClick={() => onClick('d')}>{'D. ' + test.question.d}</Container>
            </Container>
        </Section>
    );
};